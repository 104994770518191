<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
      <v-card-title>{{$t("view.PageBackendEvent.assign_classroom")}}</v-card-title>
        <v-card-text>
            <template>
              <Classroom
                :callbackSelect="selectClassroom"
                :callbackError="showError"
                  @input="form.classroomId = $event"
                  
              />
          </template>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$router.go(-1)"
        >
           {{$t("action.cancel")}}
        </v-btn>
        <v-btn
          color="primary"
          :loading="api.isLoading"
          @click="validate"
        >
           {{$t("action.confirm")}}
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-form>
    <!-- EOC -->
  </div>
</template>


<script>
import Classroom from "@/components/Bread/ClassroomOnEvent/Classroom";
export default {
  props:[
      'self',
      'role',
      'model',
      'url',
      'action',
      'callbackSuccess',
    ],
  data: () => ({
    classroom: null,
    //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
       form: {},
  }),

  components: {
    Classroom,
  },
 created() {

      console.log(this.self)
      console.log(this.form)
      //BOC:[api]
      this.api.url = this.url
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
        this.api.error = null
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false
        this.callbackSuccess(resp)
      }
      //EOC
    },
  methods: {
    selectClassroom(item) {
      this.chapter = item;
      this.form.classroomId = item.id
    },
    //BOC:[form]
      validate() {
        this.api.callbackReset()
        if(this.$refs.form.validate()) {
          this.submit()
        }
        else {
          this.api.callbackError('Please check the form.')
        }
      },
      submit() {
        this.api.params = this.$_.clone(this.form)
        this.$api.fetch(this.api)
      },
      //EOC
  },
};
</script>
